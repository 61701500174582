@media screen and (max-width: 480px) {
  
 .tabs ul li {
    background-size: 75%;
}

.oh-item .details table {
    .lh(17px);
    width: 100%;
}


.load-more a {
    margin: 0 0 10px 0;
}

.prog-item {
    margin: 0 0 15px 20px;
}

.prog-item .item-container {
    display: block;
    width: 100%;
}

.prog-item .item-container .img-container {
    display: block;
    .wh(100%,auto);
}

.prog-item .item-container .details {
    padding: 15px;
}

.prog-item .item-container .details .date {
    position: static;
    margin: 0 0 10px 0;
}



}