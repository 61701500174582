
// colors
@white: #ffffff;

@black: #28292a;

@grey: #3c3d3e;
@grey2: #b5b5b5;
@grey3: #5c5c5c;
@grey4: #c4c4c4;
@grey5: #f0f0f0;
@grey6: #cfcfcf;
@grey7: #e2e2e3;
@grey8: #f8f8f9;
@grey9: #f2f2f2;

@orange: #e53f10;
@orange2: #ee4b1c;


/*

font-family: 'Open Sans', sans-serif;
font-family: 'Jaldi', sans-serif;

*/




// open sans fonts

.open-reg() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.open-semi() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.open-bold() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.open-ita() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: italic;
}

// jaldi fonts

.jaldi-reg() {
    font-family: 'Jaldi', sans-serif;
    font-weight: 400;
}

.jaldi-bold() {
    font-family: 'Jaldi', sans-serif;
    font-weight: 700;
}



//elegant 
.elegant(@elegantcontent) {
    font-family: 'ElegantIcons';
    content: "\@{elegantcontent}";
    font-weight: normal;
}

//fontawesome 
.awesome(@awesomecontent) {
    font-family: 'FontAwesome';
    content: "\@{awesomecontent}";
    font-weight: normal;
}

// font-size
.fs(@fontsize: 14px) {
    font-size: @fontsize;
}

// line-height
.lh(@lineheight: 17px) {
    line-height: @lineheight;
}

// letterspacing
.ls(@letterspacing: 0px) {
    letter-spacing: @letterspacing;
}

// vertical align
.va(@verticalalign: top) {
    vertical-align: @verticalalign;
}

// vertical align
.ta(@textalign: left) {
    text-align: @textalign;
}

// width / height
.wh(@width, @height) {
    width: @width;
    height: @height;
}