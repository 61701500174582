// main css

@charset "utf-8";

// imports

@import "colors.less";
@import "elements.less";
@import "reset.less";



body {
    .open-reg();
    background: #f7f7f8 url('../img/bg.jpg');
}

.wrap {
    width: 1200px;
    margin: 0 auto;
}


// header

.header {
    .wh(100%,90px);
    background: url('../img/bg-noise-black.png');
    box-shadow: 0px 0px 10px 0px rgba(25, 24, 28, 0.25);
    -moz-box-shadow: 0px 0px 10px 0px rgba(25, 24, 28, 0.25);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(25, 24, 28, 0.25);
}

.header .wrap {
    position: relative;
    .ta(center);
}

.slogan {
    .wh(440px,50px);
    display: inline-block;
    background-image: url('../img/slogan.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 18px 0 0 0;
}

.menu {
    .wh(90px,90px);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    .transition-duration(.15s);
}

.menu:after {
    .awesome(f0c9);
    color: @white;
    cursor: pointer;
    .fs(22px);
    .lh(85px);
}

.menu-open .menu:after {
    .awesome(f00d);
    color: @orange;
}

.menu:hover {
    .opacity(.8);
    .transition-duration(.2s);
}

.menu-dd {
    z-index: 9999;
    position: absolute;
    .wh(100%,auto);
    background: rgba(40,41,42,.90);
    top: 90px;
    height: 0;
    overflow: hidden;
    transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -webkit-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -moz-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    -o-transition: max-height .2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.menu-open .menu-dd {
    height: auto;
    max-height: 1200px;
    transition: max-height 1s ease-out;
    -webkit-transition: max-height 1s ease-out;
    -moz-transition: max-height 1s ease-out;
    -o-transition: max-height 1s ease-out;
}

.menu-dd ul {
    display: block;
    list-style: none;
    width: 50%;
    margin: 80px auto 0 auto;
}

.menu-dd ul li {
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
}

.menu-dd ul li a {
    display: block;
    background: #343434;
    color: @white;
    .transition-duration(.1s);
    .fs(38px);
    .lh(90px);
    .ls(1px);
    .jaldi-bold();
    .box-shadow(inset 0 -4px 0 #2b2b2b);
}

.menu-dd ul li a:hover {
    background: @orange2;
    .transition-duration(.2s);
    .box-shadow(inset 0 -4px 0 #b53e1c);
}

.menu-dd ul li a:active {
    background: @orange2;
    .transition-duration(0s);
    .box-shadow(inset 0 4px 0 #b53e1c);
}

// main 

.main {
    padding: 50px 0 60px 0;
}




// map

.map {
    position: relative;
    padding-bottom: 50%; // This is the aspect ratio
    margin-left: 10px;
    margin-right: 10px;
    height: 0;
    overflow: hidden;
    margin-bottom: 50px;
    border: 1px solid #f1f1f1;
}

.map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


// tabbed

.tabbed {
    padding: 0 10px;
}

.tabs {
}

.tabs ul {
    display: table;
    width: 100%;
}

.tabs ul li {
    display: table-cell;
    width: 20%;
    background-color: #eeeeee;
    height: 100px;
    .ta(center);
    .va(middle);
    border-bottom: 2px solid #e2e2e3;
    cursor: pointer;
    .transition-duration(.05s);
    .user-select(none);
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    .opacity(.66);
}

.tabs ul li:hover {
    background-color: #e8e8e8;
    .transition-duration(.1s);
    .opacity(.85);
}

.tabs ul li.active {
    background-color: @white;
    border-bottom: 2px solid @white;
    border-top: 4px solid #d84318;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    .opacity(1);
}

.content {
    background: @white;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-bottom: 2px solid #e2e2e3;
    padding: 10px 0;
}

.t1 {
    background-image: url('../img/logo-budapest.png');
}

.t2 {
    background-image: url('../img/logo-gyor.png');
}

.t3 {
    background-image: url('../img/logo-pecs.png');
}

.t4 {
    background-image: url('../img/logo-szeged.png');
}

.t5 {
    background-image: url('../img/logo-debrecen.png');
}


.content .tc1, .content .tc2, .content .tc3, .content .tc4, .content .tc5 {
    display: none;
    padding: 40px;
    .user-select(none);
}

.content .active {
    display: block;
}

// 

.info {
    float: left;
    width: 25%;
    padding: 0 15px 0 0;
}

.list {
    float: right;
    width: 75%;
    padding: 0 0 0 15px;
}



h1 {
    .jaldi-bold();
    .fs(52px);
    .lh(46px);
    margin: 0 0 35px 0;
}


// content 

.shop-logo {
    margin: 0 0 20px 0;
}

.shop-logo img {
    .wh(100%,auto);
    border: 3px solid @grey9;
}

.opening-indicator {
    margin: 0 0 15px 0;
    text-align: center;
    .jaldi-bold();
    .fs(14px);
    .lh(14px);
    text-transform: uppercase;
    .ls(.5px);
}

.opening-title {
    display: none;
}

.opening-indicator .shop-open,
.opening-indicator .shop-closed {
    position: static;
    padding: 0;
}

.opening-indicator .shop-open:before {
    position: static;
    display: inline-block;
    margin-right: 7px;
}

.shop-open {
    position: relative;
    padding: 0 0 0 22px;
}

.shop-open:before {
    position: absolute;
    left: 5px;
    top: 16px;
    content: "";
    .wh(10px,10px);
    .rounded(50%);
    background: #22de03;
}

.open-now .shop-closed {
    display: none;
}

.closed-now .shop-open {
    display: none;
}

.opening-content table {
    width: 100%;
    margin: 0 0 20px 0;
}

.opening-content table tr td {
    background: #f6f6f6;
    border-bottom: 2px solid @white;
    padding: 7px 10px;
    .jaldi-reg();
    text-transform: uppercase;
    .fs(13px);
    .lh(13px);
    .ls(.5px);
}

.opening-content table tr td:last-child {
    .ta(right);
}

.opening-content table tr.today td {
    background: #e8e8e8;
    .jaldi-bold();
}

.more-info {
    display: block;
    .fs(13px);
    .lh(13px);
    color: @black;
    text-decoration: underline;
}

.more-info:hover {
    text-decoration: none;
    color: @orange2;
}

.hr-mini {
    .wh(100%,1px);
    padding: 25px 0 0 0;
    margin: 0 0 25px 0;
    border-bottom: 2px solid #ededed;
}

.contact-box h2 {
    margin: 0 0 15px 0;
    .fs(14px);
    .lh(14px);
    .open-bold();
}

.contact-box p {
    .fs(13px);
    .lh(17px);
}

.contact-box p a {
    color: @black;
    text-decoration: none;
}

.contact-box p a:hover {
    color: @orange;
    text-decoration: underline;
}

.social-box {
    padding-top: 15px;
    margin-bottom: 15px;
}

.social-box a {
    display: inline-block;
    .wh(36px,36px);
    .ta(center);
    background: #f6f6f6;
    margin-right: 5px;
    margin-bottom: 10px;
    color: @black;
}

.social-box a i {
    .fs(16px);
    .lh(36px);
}

.social-box a:hover {
    background: @orange2;
    color: @white;
}


// event-list


.load-more {
    .ta(center);
}

.load-more a {
    margin: 10px 0 10px 0;
    cursor: pointer;
    .rounded(0);
    border: none;
    display: inline-block;
    .wh(auto,36px);
    background: @orange;
    color: @white;
    text-transform: uppercase;
    .fs(16px);
    .lh(36px);
    .ls(1px);
    .jaldi-bold;
    padding: 0 15px;
    .box-shadow(inset 0 -3px 0 #b53e1c);
    .transition-duration(.15s);
}

.load-more a:hover {
    background: @orange2;
    .transition-duration(.15s);
}

.load-more a:active {
    .box-shadow(inset 0 3px 0 #b53e1c);
}

.prog-item {
    margin: 0 0 20px 20px;
    background: @grey8;
    border-bottom: 2px solid @grey7;
    position: relative;
}

.prog-item .type {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    position: absolute;
    left: -20px;
    top: 0;
}

.prog-item .type .txt {
    .rotation(-90deg);
    transform-origin: right top;
    text-align: right;
    .jaldi-bold();
    .fs(13px);
    .lh(20px);
    .ls(1px);
    color: @white;
    text-transform: uppercase;
    padding: 0 5px;
}

.news-item .txt {
    background: #ec4d24;
}

.sale-item .txt {
    background: #949b37;
}

.event-item .txt {
    background: #a3a28c;
}

.prog-item .item-container {
    display: table;
    width: 100%;
}

.prog-item .item-container .img-container {
    display: table-cell;
    .wh(285px,200px);
    .va(top);
    background: @grey7;
}

.prog-item .item-container .img-container img {
    .wh(100%,auto);
}

.prog-item .item-container .details {
    display: table-cell;
    padding: 15px;
    position: relative;
}

.prog-item .item-container .details .date {
    position: absolute;
    bottom: 15px;
    right: 15px;
    .jaldi-bold();
    text-transform: uppercase;
    .fs(16px);
    .lh(16px);
    color: @grey4;
}

.prog-item .item-container .details .title {
    .jaldi-bold();
    text-transform: uppercase;
    .fs(20px);
    .lh(20px);
    max-height: 40px;
    overflow: hidden;
    margin: 0 0 15px 0;
}

.prog-item .item-container .details p {
    .open-reg();
    .fs(14px);
    .lh(17px);
    max-height: 70px;
    overflow: hidden;
    margin: 0 0 10px 0;
}

.prog-item .item-container .details .read-more {
    display: inline-block;
    .jaldi-bold();
    color: @orange2;
    text-transform: uppercase;
    .ls(.5px);
    .lh(16px);
    .fs(16px);
    .va(middle);
}

.prog-item .item-container .details .read-more:after {
    .elegant(24);
    .lh(16px);
    .fs(14px);
    display: inline-block;
    .va(middle);
    padding: 0 0 0 3px;
}

.prog-item .item-container .details .read-more:hover {
    text-decoration: underline;
    color: @orange;
}










// brands 

.brands {
    .wh(100%,150px);
    background: url('../img/bg-noise-black.png');
    padding: 25px 0;
}

.brands img {
    height: 100px;
    width: auto;
    float: left;
}

#makeMeScrollable div.scrollableArea *
{
	position: relative;
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

// footer 

.footer {
    .ta(center);
    background: #3c3d3d;
    color: @white;
    .fs(14px);
    .lh(66px;);
    .jaldi-reg();
    padding: 0 0 4px 0;
}

.footer a {
    color: @white;
    text-decoration: underline;
}

.footer a:hover {
    text-decoration: none;
}

.footer i {
    .opacity(.5);
    .fs(4px);
    .va(middle);
    padding: 0 10px;
}






// media queries...

@import "1200w.less";
@import "960w.less";
@import "768w.less";
@import "480w.less";
