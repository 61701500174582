@media screen and (max-width: 960px) {
    
.wrap {
    width: 100%;
}


.prog-item .item-container .img-container {
    display: table-cell;
    .wh(150px,105px);
    .va(top);
    background: @grey7;
}








}