@media screen and (max-width: 1200px) {
    
.wrap {
    width: 960px;
    margin: 0 auto;
}






}