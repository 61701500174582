// reset & base

html {
    -webkit-text-size-adjust: 100%; 
    //-webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
}

* {
    margin: 0;
    padding: 0;
}

div, span, a, img, input, textarea, button, blockquote, li, header, footer {
    .box-sizing(border-box);
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}


img, p {
    display: block;
}

.clear {
    clear: both;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

.left {
    float: left;
    text-align: left;
}

.right {
    float: right;
    text-align: left;
}

.center {
    margin: 0 auto;
}

button:focus, textarea:focus, input:focus, select:focus{
    outline: 0;
}

h1, h2, h3, h4, h5, h6 {
    display: block;
    font-weight: normal;
    .box-sizing(border-box);
}

table {
    border-collapse: collapse;
}

strong, b {
    font-weight: 700;
}

::selection {
    background: #59c6f2;
    color: #ffffff;
}

::-moz-selection {
    background: #59c6f2;
    color: #ffffff;
}