@media screen and (max-width: 768px) {
    

// header

.header {
    .wh(100%,50px);
}

.slogan {
    .wh(220px,25px);
    margin: 11px 0 0 0;
}

.menu {
    .wh(50px,50px);
}

.menu:after {
    .fs(16px);
    .lh(48px);
}


.menu-dd {
    top: 50px;
}

.menu-dd ul {
    width: 80%;
    margin: 40px auto 0 auto;
}

.menu-dd ul li {
    margin: 0 0 15px 0;
}

.menu-dd ul li a {
    .fs(26px);
    .lh(50px);
    .ls(.5px);
    .box-shadow(inset 0 -3px 0 #2b2b2b);
}

.menu-dd ul li a:hover {
    .box-shadow(inset 0 -3px 0 #b53e1c);
}

.menu-dd ul li a:active {
    .box-shadow(inset 0 3px 0 #b53e1c);
}

// main 

.main {
    padding: 10px 0 30px 0;
}



// map

.map {
    margin-bottom: 20px;
}


// tabbed

.tabs ul li {
    height: 50px;
}

.content .tc1, .content .tc2, .content .tc3, .content .tc4, .content .tc5 {
    padding: 15px;
}

// 

.info {
    float: none;
    width: 100%;
    padding: 0 0 0 0;
}

.list {
    float: none;
    width: 100%;
    padding: 0 0 0 0;
}



h1 {
    .fs(32px);
    .lh(38px);
    margin: 0 0 15px 0;
    .ta(center);
}

.shop-logo img {
    max-width: 250px;
    margin: 0 auto;
    border: 3px solid @grey9;
}


// brands 

.brands {
    .wh(100%,100px);
    padding: 10px 0;
}

.brands img {
    height: 80px;
}

.footer {
    .fs(12px);
    .lh(46px;);
    padding: 0 0 2px 0;
}






}